import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, BannerLeft, BannerCenter, BannerButton } from "../utils"
import img from "../images/bcg/mountain-pasture.jpg"
import leafyImg from "../images/bcg/leafy-greens-lg.svg"
import imgUpper from "../images/bcg/farm-food.jpg"
import imgLower from "../images/bcg/home-grown.jpg"

const MenuPage = () => (
  <Layout>
    <SEO
      title="Menu"
      keywords={[
        `Foragers Canteen menu`,
        `breakfast lunch dinner in sylva nc`,
        `restaurants in sylva nc`,
      ]}
    />
    <PageHeader img={img}>
      <BannerLeft title="Real Food" subtitle="From Farm to Fork">
        <hr />
        <p>Fresh, organic, and locally sourced. Because you're worth it.</p>
      </BannerLeft>
    </PageHeader>
    <div className="row justify-content-center align-content-center no gutters p-0 my-0 quad-section">
      <div
        className="bc-column col-sm-6 col-md-6 col-lg-6 align-self-center order-2 order-sm-1 quad-section-img"
      >
        <img
          src={imgUpper}
          alt="Farm Food"
        ></img>
      </div>

      <div className="bc-column col-sm-6 col-md-6 col-lg-6 align-self-center order-1 order-sm-2 p-2 py-5 px-lg-5 food-copy">
        <h2>The 'Farm To Fork' Philosophy</h2>
        <p>
          We believe that truly great meals are started on farms and finished in
          kitchens. That's why 95% of the ingredients that we'll cook today
          traveled less than 200 miles to get here.
        </p>
      </div>
    </div>
    <div className="row justify-content-center align-content-center no gutters p-0 my-0 quad-section">
      <div className="bc-column col-sm-6 col-md-6 col-lg-6 align-self-center order-2 order-sm-1 py-5 py-sm-0 px-2 px-lg-5 food-copy">
        <h2>Home Grown</h2>
        <p>
          Why buy it if you can grow it? Sure, growing is a little less
          convenient. And it takes a little—okay, a lot—longer. It's also
          messier, riskier, and quite a bit more difficult. But you know what?
          All that hard work just tastes sooo gooood.
        </p>
      </div>
      <div className="bc-column col-sm-6 col-md-6 col-lg-6 align-self-center order-2 order-sm-1 quad-section-img">
        <img
          src={imgLower}
          alt="Sprouts"
        ></img>
      </div>
    </div>
    <div id="menu-links">
      <PageHeader img={leafyImg}>
        <BannerCenter title="The Menu" subtitle="Brunch. Lunch. Dinner. Oh My.">
          <BannerButton>
            <a href="/brunch-menu.pdf">Weekend Brunch</a >
          </BannerButton>
          <br />
          <BannerButton>
            <a href="/lunch-dinner-menu.pdf">Lunch & Dinner</a>
          </BannerButton>
          <br />
          <BannerButton>
            <a href="/kids-menu.pdf">Kids Menu</a>
          </BannerButton>
          <br />
          <BannerButton>
            <a href="/catering-menu.pdf">Catering Menu</a>
          </BannerButton>
        </BannerCenter>
      </PageHeader>
    </div>
  </Layout>
)

export default MenuPage
